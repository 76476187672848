.wrapper {
  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-23;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }

  & :global(.inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  }
}

.blockGrid {
  @apply grid grid-cols-1 gap-10 sm:grid-cols-2;
}

.article {
  @apply flex flex-col w-2/3;
}

.loadMoreButton {
  @apply mx-auto w-full md:max-w-1xl mt-13 flex justify-center items-center;

  & span {
    @apply px-2;
  }
}

.noMoreArticles {
  @apply w-full text-center p-4;
}
